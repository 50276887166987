import React, { useEffect, useRef } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import useViewport from "Utils/UseViewport";
import './index.css';

const AboutPage = () => { // 默认header背景颜色为蓝色
  const imageContainerRef = useRef(null); // 创建一个ref来引用图片容器
  const navigate = useNavigate();
  const { state } = useLocation();
  const { color } = state || { color: "#1A5BFF" };
  
  useEffect(() => {
    const handleScroll = (e) => {
      if (imageContainerRef.current) {
        imageContainerRef.current.scrollTop += e.deltaY; // 修改滚动位置
      }
    };
    window.addEventListener('wheel', handleScroll); // 在window上添加滚动监听
    return () => {
      window.removeEventListener('wheel', handleScroll); // 清理监听器
    };
  }, []);
  
  const { width: windowWidth, height: windowHeight } = useViewport();
  let isPortrait = windowHeight > windowWidth;
  
  if (isPortrait) {
    return (
      <div className="main-page">
        <div className="about-page-content" style={{ backgroundColor: color }}>
          <div className="about-square-container mobile">
            <div className="about-image-container mobile" ref={imageContainerRef}>
              <img src={require("Assets/images/about1.png")} alt="About 111" />
            </div>
            <img
              className="back-button1" 
              src={require("Assets/images/back.png")}
              alt="Back"
              onClick={() => {
                navigate("/event");
              }}
            />
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="main-page">
        <div className="about-page-content" style={{ backgroundColor: color }}>
          <div className="about-square-container">
            <img className="about-image1" src={require("Assets/images/about.png")} alt="About" />
            <div className="about-image-container" ref={imageContainerRef}>
              <img src={require("Assets/images/about1.png")} alt="About 111" />
            </div>
            <img
              className="back-button1" 
              src={require("Assets/images/back.png")}
              alt="Back"
              onClick={() => {
                navigate("/event");
                // TODO
              }}
            />
          </div>
        </div>
      </div>
    );
  }

}

export default AboutPage;
