import React from 'react';
import useViewport from "Utils/UseViewport";
import './guest.css'; // 引入CSS样式文件

const GuestSquare = ({ name, image, icon, url, color }) => {
  return (
    <div className="guest-square-container">
      <div className="title-font guest-name" style={{ color: color }}>{name}</div>
      <a href={`http://${url}`} target="_blank">
        <div className="guest-square" style={{ backgroundColor: color }}>
          <div className="background-image" style={{ backgroundImage: `url(${image})`}}>
            <div className="mask" />
            <img src={icon} alt={name} className="icon-image" />
          </div>
        </div>
      </a>
    </div>
  );
};

const GuestList = ({ guests, url, color }) => {
  const { width: windowWidth, height: windowHeight } = useViewport();
  
  if (!guests || guests.length === 0) {
    return null;
  }
  
  let isPortrait = windowHeight > windowWidth || windowWidth < 768;

  // 计算每个GuestSquare的宽度
  const count = guests.length;
  let calcWidth = "18.4vh";
  if (count <= 0) {
    return null;
  } else if (isPortrait) {
    calcWidth = "45vw";
  } else if (count <= 1) {
    calcWidth = "48vh";
  } else if (count < 5) {
    calcWidth = `calc(${(100 / count)}vh - ${(2 * (count - 1) / count)}vh)`;
  } else {
    calcWidth = "18.4vh";
  }

  let guestListClassName = isPortrait ? "guest-list mobile" : "guest-list";
  let guestContainerClassName = isPortrait ? "guest-list-container mobile" : "guest-list-container";

  return (
    <div className={guestContainerClassName}>
      <div className={guestListClassName}>
        {guests.map((guest, index) => {
          if (!guest) {
            return null;
          } else {
            return (
              <div key={index} style={{ width: calcWidth }}> {/* 为了间隔美观，可能需要调整margin */}
                <GuestSquare
                  name={guest.name}
                  image={`/api/public/${guest.image}`}
                  icon={`/api/public/${guest.icon}`}
                  url={guest.url}
                  color={color}
                />
              </div>
            );
          }
        } 
        
        )}
      </div>

      {/* 动态按钮 */}
      <a href={`http://${url}`} target="_blank">
        <div className="anim-button title-font" style={{marginTop: "2.5vh"}}>
          <div className="button-main" style={{ color: color }}>&emsp;SEE MORE 更多作品</div>
          <div className="button-hover" style={{ backgroundColor: color }}>
            &emsp;SEE MORE 更多作品
          </div>
        </div>
      </a>
      
    </div>
  );
}

export default GuestList;
