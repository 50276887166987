import React, { useState, useEffect } from "react";

export default function useViewport() {
  const [dimension, setDimension] = useState({
    height: window.innerHeight,
    width: window.innerWidth,
  });
  useEffect(() => {
    const handleWindowResize = () =>
      setDimension({
        height: window.innerHeight,
        width: window.innerWidth,
      });
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);
  return dimension;
}
