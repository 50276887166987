import React, { useState, useEffect, Fragment } from 'react';
import { useNavigate } from "react-router-dom";
import { PageSlides, SlideParallaxType } from "react-page-slides";
import axios from "axios";
import useViewport from "Utils/UseViewport";
import GuestList from './guest';
import './index.css';

const MainPage = () => {
  // const [color, setColor] = useState('#1A5BFF'); // 默认颜色为蓝色
  const [events, setEvents] = useState([]);
  const [guests, setGuests] = useState([]);
  
  const { width: windowWidth, height: windowHeight } = useViewport();
  let isPortrait = windowHeight > windowWidth;

  useEffect(() => {
    axios.get("/api/event")
      .then((res) => {
        if (res.data.success) {
          setEvents(res.data.data.rows);
        } else {
          throw new Error("获取数据失败");
        }
      }).catch((e) => {
        console.log(e);
      });

    axios.get("/api/guest")
      .then((res) => {
        if (res.data.success) {
          setGuests(res.data.data.guests);
        } else {
          throw new Error("获取数据失败");
        }
      }).catch((e) => {
        console.log(e);
      });

  }, []);

  
  const AllSlides = [];
  if (isPortrait) {
    for (let i = 0; i < events.length; i++) {
      const pickedId = JSON.parse(events[i].guests);
      const pickedGuests = pickedId.map((pid) => guests.find((guest) => guest.id === pid));
      AllSlides.push(
        <MainSlide key={i} color={events[i].color} bgImage={events[i].bg_image} guests={pickedGuests} url={events[i].url}/>
      );
    }
    return (
      <Fragment>
        {AllSlides}
      </Fragment>
    );
  } else {
    for (let i = 0; i < events.length; i++) {
      const pickedId = JSON.parse(events[i].guests);
      const pickedGuests = pickedId.map((pid) => guests.find((guest) => guest.id === pid));
      AllSlides.push(
        {
          content: <MainSlide key={i} color={events[i].color} bgImage={events[i].bg_image} guests={pickedGuests} url={events[i].url}/>
        }
      );
    }

    return (
      <PageSlides
        enableAutoScroll={true}
        transitionSpeed={1000}
        slides={AllSlides}
        parallax={{
            offset: 0.6,
            type: SlideParallaxType.reveal
        }}
      />
    );
  }

}


const MainSlide = ({color, bgImage, guests, url}) => { // 默认header背景颜色为蓝色
  const navigate = useNavigate();
  const { width: windowWidth, height: windowHeight } = useViewport();
  let isPortrait = windowHeight > windowWidth;
  let logoClassName = isPortrait ? "logo mobile" : "logo";
  let aboutButtonClassName = isPortrait ? "title-font about-button mobile" : "title-font about-button";
  let footerClassName = isPortrait ? "footer-container mobile" : "footer-container";
  let squareClassName = isPortrait ? "protrait-component" : "square-component";

  return (
    <div className="main-page"
      style={{
        backgroundImage: `url(/api/public/${bgImage})`,
        backgroundSize: isPortrait ? "auto" : "cover",
      }}
    >
      <div className={squareClassName}>
        <div className="header" style={{ backgroundColor: color }}>
          <img src={require("Assets/images/logo.png")} alt="Logo" className={logoClassName}/>
          <button
            className={aboutButtonClassName}
            style={{ color: color }}
            onClick={() => {
              navigate("/about", { state: { color: color } });
            }}
          >
            ABOUT 111
          </button>
        </div>
        <div className="title-container">
          <div className="title-font" style={{color: color, paddingLeft: isPortrait ? "2vh" : 0}}>
            invited guests
          </div>
          <div className="title-font" style={{color: color, paddingLeft: isPortrait ? "2vh" : 0}}>
            特邀创作者
          </div>
          <div className="divider" style={{ backgroundColor: color, marginBottom: "2vh"}}/>
        </div>

        <GuestList guests={guests} url={url} color={color} />

        <div className={footerClassName}>
          <div className="divider" style={{ backgroundColor: color, marginTop: "2vh"}}/>
          <div
            className="desc-container title-font"
            style={{color: color, paddingRight: isPortrait ? "2vh" : 0}}
          >
            <span style={{fontSize: "1.5vh"}}>{"producer & designer"}</span>&nbsp;TaiKOInaut
          </div>
        </div>
      </div>
    </div>
  );
}

export default MainPage;
