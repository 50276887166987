import React from 'react';
import { useNavigate } from "react-router-dom";
import useViewport from "Utils/UseViewport";
import './index.css';


const HomePage = () => {
  const navigate = useNavigate();
  const { width: windowWidth, height: windowHeight } = useViewport();
  let isPortrait = windowHeight > windowWidth;
  let backgroundClassName = isPortrait ? "moving-background mobile" : "moving-background";
  let buttonClassName = isPortrait ? "main-anim-button mobile" : "main-anim-button";
  let buttonImageClassName = isPortrait ? "main-button-image mobile" : "main-button-image";


  return (
    <div className={backgroundClassName}>
      <div className={buttonClassName}>
        <img src={require("Assets/images/homebtn1.png")} alt="Button" className={buttonImageClassName}/>
        <div
          className="main-hover-overlay" 
          onClick={() => {
            navigate("/event");
          }}
        />
      </div>
    </div>
  );
}

export default HomePage;
